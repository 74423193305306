.wraper {
  width: 100%;
  height: 100%;
  /* background-color: red;     */
}
.btn_group {
  width: 340px;

  border-radius: 5px;
  display: flex;

  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  background-color: #f5f5f9;
  border: 0.5px solid #727271;
}
.btn_one {
  /* float: left; */
  line-height: 20px;
  color: #7272716b;
  transition: 0.1s;
  margin: 0;
  padding: 5px 0;
  border-radius: 5px;
  width: 33.33%;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}
.btn_two {
  float: left;
  line-height: 20px;
  color: #7272716b;
  margin: 0;
  width: 33.33%;

  cursor: pointer;
}
.btn_three {
  float: left;
  width: 33.33%;
  line-height: 20px;
  color: black;
  margin: 0;
  cursor: pointer;
  text-align: right;
}
.btn_bg {
  background-color: #ef6817;
  height: 100%;
  width: 33.33%;
  position: absolute;
  top: 0;
  left: 0%;
  z-index: -1;
  transition: 0.2s;
  border-radius: 20px;
}

.wraperManage .btn_two {
  width: 50% !important;
  padding-left: 12px;
}
.wraperManage .btn_one {
  width: 50% !important;
}
.wraperManage .btn_bg {
  width: 50% !important;
}
.wraperManage .btn_group {
  padding: 10px 0px;
}
.activeSlider {
  color: white;
}
.catlog_active {
  border: 1px solid #727271;
}

.discount {
  font-size: 1.25em !important;
}

.datatables-basic tr td {
  color: var(--bs-gray-e-dark);
}
.unitSpan {
  font-size: 0.75em !important;
  display: flex;
  color: var(--bs-gray-e-dark) !important;
  align-items: center;
}

.catlogUnitSpan {
  font-size: 0.75em !important;
  /* display: flex; */
  color: var(--bs-gray-e-dark) !important;
}

.showAleryDialog {
  background: "#00000080";
  display: "block";
  z-index: 999999999999999999;
}

.attrprice li {
  display: inline-block;
  /* list-style: inline; */
  padding: 3px;
  border: 1px solid;
  margin: 1px;
  border-radius: 4px;
}
.attrContainer {
  list-style: none;
  margin: 0;
  padding: 0;
}

.acceptOrder {
  margin: 0;
  padding: 0;
}

.report_filter_card {
  font-size: 0.7em !important;
}
.report_filter_card .active {
  background-color: #7272716b !important;
  color: white;
  border: #ef6817;
}
