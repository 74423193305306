.partial_or_list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.partial_or_list li {
  display: flex;
  justify-content: space-between;
}
.partial_or_list .orderd_image_containe {
  /* width: 20%; */
}
.o_d_title_cell {
  width: 35%;
}

.partial_or_list .o_d_price_cell {
  width: 20%;
}
.partial_or_list .totalCell{
    width: 12%;
    display: flex;
    justify-content: space-between;
}