.bill_address {
  display: block;
  font-size: 0.8em;
}

.changepartybtn {
  font-size: 0.7em;
  /* background-color: #f5f5f9; */
  padding: 0.2em 0.7em;
  float: right;
}

.changepartybtn select {
  background-color: #f5f5f9;
  font-size: 1em;
  padding: 0.1em 1.2em;
}

.party_name {
  color: black;
}

.invoiceHeading {
  font-size: 0.8em;
}

.invoiceDateSection .card-body {
  padding: 1.2em 1.5em;
}
.invoice-input {
  width: 80%;
  border-radius: 5px;
  border: none;
  background-color: #f5f5f9;
  font-size: 0.6em;
  padding: 0.4em;
}
.qty {
  width: 50% !important;
  text-align: right;
  font-size: 1.2em;
}
.amount {
  font-size: 1.2em !important;
  text-align: right;
}

.invoiceProductTable .input-group .form-control {
  font-size: 0.9em;
  padding: 0 0.3em;
  background: #f5f5f9;
  border: none;
  text-align: right;
}

.invoiceProductTable .input-group-text {
  padding: 0em 0.2em;
  border: none;
  background: #f5f5f9;
}
.invoiceProductTable thead tr th {
  border: 1px solid black;
  padding: 0.8em;
  font-size: 0.6em;
  background: #f5f5f9;
}

.invoiceProductTable tfoot tr td {
  border: 1px solid black;
  padding: 0.8em;
  font-size: 0.8em;
  background: #f5f5f9;
}

.invoiceProductTable tbody tr td {
  border: 1px solid black;
  padding: 0.8em;
  font-size: 0.7em;

  /* background: #f5f5f9; */
}
.fixed-width {
  width: 10em !important;
}

.additem_div {
  text-align: center;
  border: 2px dashed #7272716b;
  border-radius: 5px;
  padding: 1em 0;
}

.modalSearch {
  width: 50% !important;
}

.bankDetails ul {
  list-style: none;
}
.bankDetails ul li {
  font-size: 0.8em;
}
.bankDetails ul li span {
  font-weight: bold;
  font-size: 120%;
}

.signatory {
  text-align: right;
  margin-bottom: 0 !important;
}

.signatory span {
  display: block;
}

@media print {
  body {
    padding: 20px;
  }
  .row {
    --bs-gutter-x: 1.625rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }

  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  /* invoice setting */

  .bill_address {
    display: block;
    font-size: 0.8em;
  }

  .changepartybtn {
    font-size: 0.7em;
    /* background-color: #f5f5f9; */
    padding: 0.2em 0.7em;
    float: right;
  }

  .changepartybtn select {
    background-color: #f5f5f9;
    font-size: 1em;
    padding: 0.1em 1.2em;
  }

  .party_name {
    color: black;
  }

  .invoiceHeading {
    font-size: 0.8em;
  }

  .invoiceDateSection .card-body {
    padding: 1.2em 1.5em;
  }
  .invoice-input {
    width: 100%;
    border-radius: 5px;
    border: none;
    background-color: #f5f5f9;
    font-size: 0.7em;
    padding: 0.4em;
  }
  .qty {
    width: 50% !important;
    text-align: right;
    font-size: 1.2em;
  }
  .amount {
    font-size: 1.2em !important;
    text-align: center;
    background-color: transparent;
  }

  .invoiceProductTable .input-group .form-control {
    font-size: 0.9em;
    padding: 0 0.3em;
    background: #f5f5f9;
    border: none;
    text-align: right;
  }

  .invoiceProductTable .input-group-text {
    padding: 0em 0.2em;
    border: none;
    background: #f5f5f9;
  }
  .invoiceProductTable thead tr th {
    border: 1px solid black;
    padding: 0.8em;
    font-size: 0.6em;
    background: #f5f5f9;
  }

  .invoiceProductTable tfoot tr td {
    border: 1px solid black;
    padding: 0.8em;
    font-size: 0.8em;
    background: #f5f5f9;
  }

  .invoiceProductTable tbody tr td {
    border: 1px solid black;
    padding: 0.8em;
    font-size: 0.7em;

    /* background: #f5f5f9; */
  }
  .fixed-width {
    width: 8em !important;
  }

  .additem_div {
    text-align: center;
    border: 2px dashed #7272716b;
    border-radius: 5px;
    padding: 1em 0;
  }

  .modalSearch {
    width: 50% !important;
  }

  .bankDetails ul {
    list-style: none;
  }
  .bankDetails ul li {
    font-size: 0.8em;
  }
  .bankDetails ul li span {
    font-weight: bold;
    font-size: 120%;
  }

  .signatory {
    text-align: right;
    margin-bottom: 0 !important;
  }

  .signatory span {
    display: block;
  }

  table {
    caption-side: bottom;
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    color: #a1acb8;
    text-align: left;
  }

  th {
    font-weight: 600;
    text-align: inherit;
    text-align: -webkit-match-parent;
  }

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .m-0 {
    margin: 0 !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: white;
  }
  .card-header {
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 0 solid #d9dee3;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1.5rem 1.5rem;
  }

  .invoiceProductTable .input-group .form-control {
    font-size: 0.9em;
    padding: 0 0.3em;
    background: #f5f5f9;
    border: none;
    text-align: right;
  }

  .invoiceProductTable .input-group-text {
    padding: 0em 0.2em;
    border: none;
    background: #f5f5f9;
  }
  .invoiceProductTable thead tr th {
    border: 1px solid black;
    padding: 0.8em;
    font-size: 0.6em;
    background: #f5f5f9;
  }

  .invoiceProductTable tfoot tr td {
    border: 1px solid black;
    padding: 0.8em;
    font-size: 0.8em;
    background: #f5f5f9;
  }

  .invoiceProductTable tbody tr td {
    border: 1px solid black;
    padding: 0.8em;
    font-size: 0.7em;

    /* background: #f5f5f9; */
  }

  .bankDetails ul {
    list-style: none;
  }
  .bankDetails ul li {
    font-size: 0.8em;
  }
  .bankDetails ul li span {
    font-weight: bold;
    font-size: 120%;
  }

  .signatory {
    text-align: right;
    margin-bottom: 0 !important;
  }

  .signatory span {
    display: block;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.4375rem 0.875rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.53;
    color: #727271;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #7272716b;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.4375rem 0.875rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.53;
    color: #697a8d;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d9dee3;
    border-radius: 0.375rem;
  }

  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }

  .text-right {
    text-align: right;
  }

  .border{
    background-color: #697a8d;
    color: white;
    font-weight: bold;
      
  }
}
