@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

.login-page {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}

.cancel_btn {
  color: rgb(239, 104, 23);
  background: white;
  font-weight: bold;
  width: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 7px 30px;
  border-color: rgb(239, 104, 23);
}

.loginh1 {
  font-weight: bold;
  margin: 0;
  color: #727271;
}

.overlay h1 {
  color: white;
}
h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.forgetpass {
  color: red !important;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.login-page span {
  font-size: 12px;
}

.login-page a {
  color: black;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}
.login-page a:hover {
  color: #ef6817;
}

.login_form-active {
  display: none !important;
}

.login-page p {
  border-radius: 10px;
  border: 1px solid #ef6817;
  background-color: #ef6817;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.login-page p:active {
  transform: scale(0.95);
}

.login-page p:focus {
  outline: none;
}

.login-page p.ghost {
  background-color: white;
  color: black;
}
.login-page p.ghost:hover {
  background-color: #eeeeee;
}

.login-page button {
  border-radius: 10px;
  border: 1px solid #ef6817;
  background-color: #ef6817;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.login-page button:active {
  transform: scale(0.95);
}

.login-page button:focus {
  outline: none;
}

.login-page button.ghost {
  background-color: white;
  color: black;
}
.login-page button.ghost:hover {
  background-color: #eeeeee;
}

.login-page form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.login-input {
  padding: 12px 45px;
  background-color: whitesmoke;
  border: none;
}

.login-input:focus {
  background-color: whitesmoke;
}

.login-input:-internal-autofill-selected {
  background-color: whitesmoke !important;
  border: 1px solid #ef6817;
}

.otpbutton {
  background-color: white;
  border: 1px solid #ef6817;
  color: black;
}

.otpbutton:hover {
  background-color: whitesmoke;
  color: black;
}

.login-page .container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  margin-top: 4em;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #ef6817;
  /* background: -webkit-linear-gradient(to right, #EF6817, #FF416C);
  background: linear-gradient(to right, #EF6817, #FF416C); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

/* otp section is here */
.digit-group {
  width: 40px;
  height: 40px;
  background-color: #7272716b;
  border: none;
  border-radius: 50%;

  line-height: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: 200;
  color: black;
  margin: 1em 0.15em;
}
.otpInput {
  display: none;
}

.showVerity {
  display: block !important;
}

.subcategory {
  float: left;
  border: 1px solid black;
  margin: 0.15em;
  border-radius: 0.25em;
  padding: 0 0.35em;
}

.addnewproduct {
  border: 1px solid #7272716b;
  border-radius: 0.5em;
  background-color: white;
  padding-top: 2em;
}
.heading h6 {
  margin: 1rem 0;
}

.newHyperLink {
  text-decoration: underline;
  color: blue;

  border-radius: 20px;
  padding: 0.2rem 0.3rem;
  float: right;
}
.image_div_list i {
  font-size: 3em;
}
.normalhyperlink {
  text-decoration: underline;
  color: blue;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.image_data {
  height: 150px;
  padding: 5px;
}
.image_content {
  border: 1px solid;
  height: 100%;
  border-radius: 4px;
  padding: 4px;
}

/* uli list image */
.image_div_ul {
  list-style-type: none;
  white-space: pre-line;
  width: 100%;
}
.image_div_list {
  overflow: hidden;
  text-align: center;
  position: relative;
  display: table-cell;
  float: left;
  width: 150px;
  height: 150px;
  border-radius: 5px;
  padding: 4px;
  border: 2px dashed #7272716b;
  margin: 2px;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.image_div_content {
  width: 100%;
}

.hinttext {
  font-size: 85%;
  clear: both;
}

.action_buttons {
  position: absolute;
  right: 2%;
  top: 2%;
  font-size: 0.5em;
  color: black;
}

.product-heading {
  font-size: 0.55em;
  color: #727271;
  margin: 0;
  padding: 0;
}
.product-value {
  color: black;
  font-weight: bold;
  font-size: 0.85em;
}
.product_price {
  color: black;
  font-size: 0.8em;
  font-weight: 600;
  /* border:1px solid #727271;   */
  padding: 5px 2px;
  border-radius: 5px;
  background-color: #f5f5f9;
  margin: 0;
}

.product_price:hover {
  border: 1px solid black;
  background-color: #7272712b;
}
.product_price.active {
  background-color: #7272712b;
  border: 1px solid black;
}
.product_mrp {
  display: block;
}
.product_unit {
  font-size: 70%;
  color: black !important;
}
.product_moq {
  font-size: 0.7em;
  display: block;
}
.product_var_heading {
  color: #727171;
  display: block;
}

.product_var_heading .catogory {
  display: block;
}
.stockstatus {
  position: absolute;
  right: 2%;
  top: 2%;
}

.product_title {
  font-size: 1.3em;
  color: black;
  font-weight: bold;
}

.product_desc {
  color: #7272718b;
}

.prodcut_price_div {
  /* border:1px solid black; */
  /* border-radius: 10px; */
  padding: 2px;
}
.cartIcondiv {
  position: absolute;
  bottom: 0%;
  right: 2%;
  background-color: white;
  width: 25% !important;
}

.variationList {
  margin-bottom: 1em;
  text-align: center;
}

.variation {
  border: 1px solid #7272716b;
  border-radius: 5px;
  padding: 2px 15px;
  font-size: 13px;
  color: black !important;
}
.variation:hover {
  background-color: #7272712b;
}

.variation-small {
  font-size: 80%;
}

.variation.active {
  background: #fff8f4 !important;
  color: black !important;
  font-weight: "bold";
  border: 1px solid #ef6817;
}

.category_list_div::-webkit-scrollbar {
  display: none;
}

.category-filter .variation {
  background: white;
  padding: 0.32em 0.75em;
  cursor: pointer;
  border-radius: 4px !important;
}
.category-filter .variation:hover {
  background-color: #7272712b !important;
}
.category-filter .variation.active {
  background-color: #fff8f4 !important;
  border: 1px solid #ef6817;
  color: black !important;
  font-weight: bold;
}

.buttonBadges {
  width: fit-content;
  background-color: #fff8f4;
  border: 1px solid #ef6817;
  border-radius: 7px;
  color: #ef6817;
  font-size: 15px;
}

.product_qty {
  padding: 0;
  margin: 0;
  /* margin-left: auto; */
  width: 100%;
}
.product_qty .product_no {
  font-size: 0.8em;
  border-top-left-radius: 3em !important;
  border-bottom-left-radius: 3em !important;
  border-color: #ef6817;
  color: black;
  padding: 0.175rem 0.875rem;
}
.product_qty .btn {
  font-size: 0.8em;
  font-weight: bold;
  border-top-right-radius: 3em !important;
  border-bottom-right-radius: 3em !important;
  background-color: #ef6817;
  border-color: #ef6817;
  color: white;
  padding: 0.175rem 1.6rem;
}
.product_brand {
  color: #727271;
  font-size: 60%;
  font-weight: normal;
}
.product_desc {
  display: block;
  color: #727271;
}

.supplier_name {
  font-size: 1.3em;
  color: black;
  font-weight: bold;
}

.bullet {
  font-size: 0.55em;
  color: #7272717b;
}

.cartqty {
  width: 11% !important;
}

.action {
  width: 8em !important;
}

.cart-footer {
  color: black;
}
.cart_total {
  font-weight: bold;
  font-size: 1.5em;
}

/* product table layout css */
.product_table {
  background-color: #ffffff;
}
.product_table .product_table_header tr th {
  width: 33%;
  background-color: #f5f5f9;
  color: black;
}

.product_table .product_table_body tr td {
  width: 33% !important;
  overflow: hidden;
  padding: 1.5em 0.5;
}

.pricingList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.pricingList li {
  background-color: #f5f5f9;
  margin: 0.5em 0;
  text-align: center;
  border-radius: 10px;
  padding: 5px 0;
}

.pricingList li span {
  font-size: 0.8em;
}
